import { color, font, hover, mediaBreakpointUp, vw } from '@/style/mixins';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color('blue')};
    padding: ${vw(20, 'xs')};

    ${mediaBreakpointUp('md')} {
        padding: ${vw(20, 'md')} ${vw(40, 'md')};
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: ${vw(20, 'xl')} ${vw(26, 'xl')};
    }

    ${mediaBreakpointUp('xxl')} {
        padding: ${vw(20, 'xxl')} ${vw(30, 'xxl')};
    }

    ${mediaBreakpointUp('fhd')} {
        padding: ${vw(27)} ${vw(160)};
    }
`;

export const Text = styled.p`
    ${font('text2-b')};
    color: ${color('white')};

    a {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
        border-bottom: 1px solid ${color('white')};
        padding-bottom: 3px;

        ${hover(`opacity: 0.5`)};
    }
`;

export const Button = styled.button`
    padding: 15px 24px;
    border-radius: 8px;
    margin-top: ${vw(24, 'xs')};
    width: fit-content;
    cursor: pointer;

    ${font('button')};
    background-color: ${color('white')};
    color: ${color('black')};

    ${hover(`span { opacity: 0.5 }`)};

    ${mediaBreakpointUp('md')} {
        margin-top: ${vw(24, 'md')};
    }

    ${mediaBreakpointUp('xl')} {
        padding: 13px 26px;
        margin-top: 0;
        margin-left: 20px;
    }

    ${mediaBreakpointUp('xxl')} {
        padding: 15px 30px;
    }

    ${mediaBreakpointUp('fhd')} {
        padding: ${vw(20)} ${vw(40)};
        margin-left: ${vw(20)};
    }

    span {
        word-break: normal;
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
    }
`;
