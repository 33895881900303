import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { Container, Text, Button } from './CookieMessage.styled';
import useTranslation from '@/hooks/useTranslation';

interface CookieMessageProps {}

const CookieMessage: React.FC<CookieMessageProps> = () => {
    const { locale } = useRouter();
    const { t: tCommon } = useTranslation('common');
    const [show, setShow] = useState(false);

    useEffect(() => {
        const hasAcceptedCookie = Cookies.get(`cookieMessage_${locale}`);
        setShow(!hasAcceptedCookie);
    }, [locale]);

    const onClickAcceptCookie = () => {
        Cookies.set(`cookieMessage_${locale}`, 'ok', { expires: 365 });
        setShow(false);
    };

    return show ? (
        <Container>
            <Text dangerouslySetInnerHTML={{ __html: tCommon('cookieMessage') }} />
            <Button onClick={onClickAcceptCookie}>
                <span>{tCommon('accept')}</span>
            </Button>
        </Container>
    ) : null;
};

export default CookieMessage;
